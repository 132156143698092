import styled, { keyframes } from 'styled-components'

const Fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const FadeIn = styled.div`
  animation: ${Fade} 1500ms;
`
export default FadeIn
